@keyframes shrinkLabel {
    from {transform:translateY(0em)}
    to {transform:translateY(-1.5em)}
  }

.field-wraper:focus-within > .field-label{
    
    transform:translateY(-1.5em);
    
    animation-name: shrinkLabel;
    animation-duration: .1s;
    display:inline-block;
}

.field-label{
   display:none;
}

/* Calendar classes */

.react-calendar {
  /* width: 350px; */
  max-width: 100%;
  background: white;
  border: none;
  font-family:"Roboto", "Noto";
  line-height: 1.125em;
  font-size:.9rem;
  
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
  /* background-color:var(--color-lighten-primary); */
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
  /* background:#1565c0; */
  /* background:#e6e6e6; */
  background:var(--color-primary);
  color:white;
  font-weight: 500;
  font-size:12px;
  font-family:Roboto, Noto;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover {
  /* background-color:rgba(249,249,249,0.3); */
  background-color:var(--color-lighten-primary) !important;
  border-radius:16px;
}
.react-calendar__navigation button:enabled:focus {
  /* background-color:rgba(249,249,249,0.3); */
  background-color:var(--color-lighten-primary) !important;
  border-radius:16px;
  border:none !important;
}
.react-calendar__navigation button[disabled] {
  /* background-color: #f0f0f0; */
  background-color:var(--color-lighten-primary) !important;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  border-radius:16px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
  /* background-color:var(--color-lighten-primary) !important */
}
.react-calendar__tile--now {
  background: rgba(0, 0, 0, 0.04);
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: rgba(0, 0, 0, 0.04);
  
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  /* background: #006edc; */
  background:var(--color-primary) !important;
  
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  /* background-color: #e6e6e6; */
  background:var(--color-lighten-primary) !important
}

.calendar-item{
  padding:0px !important;
}

.calendar-item > div{
  padding:0px !important;
}

.snackbar-appear {
  animation-name: slide;
  transform:none;
  min-width: 0px;
  animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes slide {
	0% {
		transform: translate(-90%);
	}
	100% {
		transform: translateX(0%)
	}
}

.animation_spin{
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.scale-left-to-right {
	  -webkit-animation: scale-left-to-right-k 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite;
    animation: scale-left-to-right-k 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite;
}

 @-webkit-keyframes scale-left-to-right-k {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes scale-left-to-right-k {
  0% {
    
    -webkit-transform: scaleX(0.1);
            transform: scaleX(0.1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity:0
  }
}

.modal-appear {
  animation-name: show_modal;
  animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .2s;
  animation-fill-mode: forwards;
  transform: scale(0);
}



@keyframes show_modal {
  from{
    
    opacity:0;
    
  } 
  to{
    
  
  transform:translate(-50%, -50%);
  opacity: 1;
  
  }
}