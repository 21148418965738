@charset "UTF-8";

:root {
  --color-gray:#666666;
  --text-12:12px ;
}

.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
  font-size: var(--text-12 , 12px);
  font-weight: 200;
}

.rbc-header {
  color: var(--color-gray, #666666);
  font-size: var(--text-12 , 12px);
  font-family: "Roboto", "Noto";
  font-weight: 600;
}

.rbc-toolbar .rbc-toolbar-label {
  font-weight: 500;
  color: var(--color-gray, #666666);
}
.rbc-toolbar button {
  font-weight: 500;
  color: var(--color-gray, #666666);
}

.rbc-show-more {
  font-size: var(--text-12 , 12px);
  color: var(--color-gray, #666666);
}

.rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
  color: var(--color-gray, #666666);
}

.rbc-overlay > * + * {
  font-size: var(--text-12 , 12px);
}

.rbc-overlay-header {
  color: var(--color-gray, #666666);
  font-weight: 600;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  color: var(--color-gray, #666666);
}