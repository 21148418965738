.react-tooltip-lite {
    background:rgb(250,250,250);
    box-sizing: border-box;
    border: .7px solid rgba(226, 232, 240, 1);
    border-radius: 8px;
}

.react-tooltip-lite-arrow {
    border-color: rgba(226, 232, 240, 1);
    
}